import utility from '@/common/utility'

const G26SpreadInfo = {
  columns: [
    {
      label: '序号',
      dataKey: 'serialNo',
      width: '50'
    },
    {
      label: '工程名称',
      dataKey: 'engineeringListName',
      width: '100',
      children: [
        {
          label: '工程类别',
          dataKey: 'parentEngineeringListName',
          width: '150'
        },
        {
          label: '工程名称',
          dataKey: 'engineeringListName',
          width: '200'
        }
      ]
    },
    {
      label: '单位',
      dataKey: 'unit',
      width: '60'
    },
    {
      label: '合同数量',
      dataKey: 'num',
      children: [
        {
          label: '原合同数量',
          dataKey: 'num',
          width: '140'
        },
        {
          label: '按施工图调整后数量',
          dataKey: 'pictureNum',
          width: '140'
        },
        {
          label: '变更数量',
          dataKey: 'changeNum',
          width: '140'
        },
        {
          label: '变更后数量',
          dataKey: 'afterChangeNum',
          width: '140'
        }
      ]
    },
    {
      label: '年度计划',
      dataKey: 'currentYearNum',
      children: [
        {
          label: '工程量',
          dataKey: 'currentYearNum',
          width: '90'
        }
      ]
    },
    {
      label: '本月计划',
      children: [
        {
          label: '工程量',
          dataKey: 'currentMonthNum',
          validator (row) {
            if (row.currentMonthNum !== undefined && row.currentMonthNum !== null && row.currentMonthNum !== '') {
              const len = row.unit.split('\\').length
              const currentMonthNumList = row.currentMonthNum.toString().split('\\')
              if (len !== currentMonthNumList.length) {
                return false
              }
              if (!currentMonthNumList.every(item => !isNaN(item))) {
                return false
              }
            }
            return true
          },
          editable: true,
          width: '110'
        },
        {
          label: '占年工程量%',
          dataKey: 'currentMonthPercent',
          validator (row) {
            if (row.currentMonthPercent !== undefined && row.currentMonthPercent !== null && row.currentMonthPercent !== '') {
              const len = row.unit.split('\\').length
              const currentMonthPercentList = row.currentMonthPercent.toString().split('\\')
              if (len !== currentMonthPercentList.length) {
                return false
              }
              if (!currentMonthPercentList.every(item => !isNaN(item))) {
                return false
              }
            }
            return true
          },
          formula (row) {
            const len = row.unit.split('\\').length
            const currentYearNumList = row.currentYearNum.split('\\')
            const currentMonthNumList = row.currentMonthNum.toString().split('\\')
            if (len === currentMonthNumList.length) {
              return currentMonthNumList.map((currentMonthNum, index) => {
                const result = utility.floatMultiple(utility.floatDivide(currentMonthNum, currentYearNumList[index]), 100).toFixed(2)
                return isNaN(result) ? (100 * parseFloat(currentMonthNum)).toFixed(2) : result
              }).join('\\')
            } else {
              return ''
            }
          },
          width: '150'
        }
      ]
    },
    {
      label: '本月计划年度累计',
      children: [
        {
          label: '工程量',
          dataKey: 'totalMonthNum',
          validator (row) {
            if (row.totalMonthNum !== undefined && row.totalMonthNum !== null && row.totalMonthNum !== '') {
              const len = row.unit.split('\\').length
              const totalMonthNumList = row.totalMonthNum.toString().split('\\')
              if (len !== totalMonthNumList.length) {
                return false
              }
              if (!totalMonthNumList.every(item => !isNaN(item))) {
                return false
              }
            }
            return true
          },
          formula (row, defaultDataList) {
            const len = row.unit.split('\\').length
            const currentMonthNumList = row.currentMonthNum.toString().split('\\')
            const aggregationYearNumList = defaultDataList[row.serialNo - 1].aggregationYearNum.toString().split('\\')
            if (len === currentMonthNumList.length) {
              if (defaultDataList[row.serialNo - 1].aggregationYearNum === '') {
                return row.currentMonthNum
              } else {
                return currentMonthNumList.map((currentMonthNum, index) => {
                  const result = utility.floatAdd(currentMonthNum, aggregationYearNumList[index]).toFixed(2)
                  console.log('result', result)
                  return isNaN(result) ? (parseFloat(currentMonthNum)).toFixed(2) : result
                }).join('\\')
              }
            } else {
              return ''
            }
          },
          width: '110'
        },
        {
          label: '占年工程量%',
          dataKey: 'totalMonthPercent',
          validator (row) {
            if (row.totalMonthPercent !== undefined && row.totalMonthPercent !== null && row.totalMonthPercent !== '') {
              const len = row.unit.split('\\').length
              const totalMonthPercentList = row.totalMonthPercent.toString().split('\\')
              if (len !== totalMonthPercentList.length) {
                return false
              }
              if (!totalMonthPercentList.every(item => !isNaN(item))) {
                return false
              }
            }
            return true
          },
          formula (row) {
            const len = row.unit.split('\\').length
            const totalMonthNumList = row.totalMonthNum.toString().split('\\')
            const currentYearNumList = row.currentYearNum.split('\\')
            if (len === totalMonthNumList.length) {
              return totalMonthNumList.map((totalMonthNum, index) => {
                const result = utility.floatMultiple(utility.floatDivide(totalMonthNum, currentYearNumList[index]), 100).toFixed(2)
                return isNaN(result) ? (100 * parseFloat(totalMonthNum)).toFixed(2) : result
              }).join('\\')
            } else {
              return ''
            }
          },
          width: '140'
        }
      ]
    }
  ]
}

export default G26SpreadInfo
